<template>
  <f7-page name="survey">
    <f7-link href="https://www.getaccept.com" external class="surveylogo"
      ><img src="static/images/logo.svg"
    /></f7-link>
    <div class="survey">
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSe4OD6KcjyL99PXle3sQ4WMMzN1FglBh9I5YC2VZLS3CSAq0A/viewform?embedded=true"
        width="100%"
        height="1100"
        class="form"
        scrolling="no"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
        >Loading…</iframe
      >
    </div>
  </f7-page>
</template>
<script>
export default {
  data(){
    return {
      retries: 5,
      attempts: 0,
      done: false,
      authChecker: null
    }
  },
  methods:{
    checkAuth(){
      console.log('Checking if authed...');
      this.attempts++
      var iframe = document.querySelector('.form')
      // console.log(iframe.contentWindow.length)
      if (iframe.contentWindow.length) {
        // User has signed in, preventing x-frame deny issue
        // Hide auth prompt overlay
        // document.querySelector('.loggedOut').style.display = 'none';
        this.done = true
      } else {
        // console.log('iframe.contentWindow.length is falsy, user needs to auth')
      }
      if (this.done || this.attempts >= this.retries) {
          clearInterval(this.authChecker)
      }

    }
  },
  mounted(){
    if (!this.authChecker){
      // this.authChecker = setInterval(this.checkAuth, 500);
    }
  },
  created() {
    
  }
}
</script>